import {AfterViewInit, ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {lyl, StyleRenderer, ThemeRef, ThemeVariables, WithStyles} from '@alyle/ui';
import {
    ImgCropperConfig,
    ImgCropperErrorEvent,
    ImgCropperEvent,
    ImgCropperLoaderConfig,
    LyImageCropper,
    STYLES as CROPPER_STYLES
} from '@alyle/ui/image-cropper';
import {Platform} from '@angular/cdk/platform';
import {AuthService} from '../services/auth.service';
import {finalize} from 'rxjs/operators';
import {AngularFireStorage} from '@angular/fire/storage';
import {SwalService} from '../services/swal.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observable, Subscription} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {MatDialogRef} from '@angular/material/dialog';

const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
    ref.renderStyleSheet(CROPPER_STYLES);
    const cropper = ref.selectorsOf(CROPPER_STYLES);

    return {
        root: lyl `{
      ${cropper.root} {
        max-width: 400px
        height: 300px
      }
    }`,
        sliderContainer: lyl `{
      text-align: center
      max-width: 400px
      margin: 14px
    }`,
        cropResult: lyl `{
      border-radius: 50%
    }`
    };
};
@Component({
    selector: 'dialog-content-example-dialog',
    templateUrl: 'cropper.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        StyleRenderer
    ]
})
export class DialogContentExampleDialog implements WithStyles, AfterViewInit {
    classes = this.sRenderer.renderSheet(STYLES, 'root');
    croppedImage?: string;
    scale: number;
    ready: boolean;
    minScale: number;
    uploadPercent: Observable<number | undefined>;
    downloadURL: Observable<any>;
    upload: Subscription;
    @ViewChild(LyImageCropper, { static: true }) readonly cropper: LyImageCropper;
    myConfig: ImgCropperConfig = {
        width: 150, // Default `250`
        height: 150, // Default `200`
        fill: '#2966ff', // Default transparent if type = png else #000
        type: 'image/png', // Or you can also use `image/jpeg`
        round: true
    };
     uid: string;

    constructor(
        readonly sRenderer: StyleRenderer,
        private _platform: Platform,
        public auth:AuthService,
        private afs: AngularFirestore,
        private afAuth: AngularFireAuth,
        public storage: AngularFireStorage,
        private swal: SwalService,
        private spinner: NgxSpinnerService,
        public dialogRef: MatDialogRef<DialogContentExampleDialog>,
    ) {

        this.uid = auth.uidata;
    }

    ngAfterViewInit() {

        // demo: Load image from URL and update position, scale, rotate
        // this is supported only for browsers
        if (this._platform.isBrowser) {
            const config: ImgCropperLoaderConfig = {
                scale: 0.745864772531767,
                xOrigin: 642.380608078103,
                yOrigin: 236.26357452128866,
                // rotation: 90,
                originalDataURL: null
            };
            this.cropper.loadImage(config);
        }

    }

    onCropped(e: ImgCropperEvent) {
        this.croppedImage = e.dataURL;
        console.log('cropped img: ', e);

        console.log(this.uid)

        if (!this.uid) {
            this.auth.data = this.croppedImage
            this.dialogRef.close(this.croppedImage);
        } else {
            this.uploadI(this.croppedImage)
        }


    }
    onLoaded(e: ImgCropperEvent) {
        console.log('img loaded', e);
    }
    onError(e: ImgCropperErrorEvent) {
        console.warn(`'${e.name}' is not a valid image`, e);
    }


    uploadI(event) {
        // take the filename from the file
        this.spinner.show()
        const file = event.split(',')[1]
        const filePath = this.uid
        const fileRef = this.storage.ref(filePath);
        //const task = this.storage.upload(filePath, file);
        fileRef.putString(file, 'base64', {contentType:'image/jpg'}).snapshotChanges().pipe(
            finalize(() => {
                    this.downloadURL = fileRef.getDownloadURL();
                    fileRef.getDownloadURL().subscribe(x => {
                        console.log(x);
                        this.afs.collection('users').doc(this.uid).update({photo: x}).then(res => {
                            this.swal.show('Updated', 'success')
                            this.onNoClick()

                            this.spinner.hide()
                        })

                    });
                }
            ))
            .subscribe()


    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
