
<nav class="navbar navbar-expand-lg bg-primary navbar-transparent navbar-absolute" color-on-scroll="500">
  <div  class="container">
    <div class="text-center" >

        <img class="text-center" style="height: 115px" src="/assets/img/logo2.png"/>



    </div>

  </div>
</nav>
  <router-outlet></router-outlet>
