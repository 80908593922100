import {AngularFireAuth} from '@angular/fire/auth';
import {Component, Inject, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {SwalService} from '../services/swal.service';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';


@Component({
    selector: 'app-request',
    templateUrl: './videos.component.html',
    styleUrls: ['./videos.component.scss']
})

export class VideosComponent implements OnInit {

    uid: string;
    user: any;
    playlists:  any = [];


    constructor(
        private router: Router,
        private afs: AngularFirestore,
        private swal: SwalService,
        private yt:AuthService,
        public dialog: MatDialog,
        private afAuth: AngularFireAuth,
    ) {


    }


    ngOnInit() {

       this.yt.getListVideos().subscribe(res=>{
           this.playlists = res['items']
       })


    }



    clicked(video: any) {
        const dialogRef = this.dialog.open(YtDialog,{
            data:video
        });


    }

}
@Component({
    selector: 'ty-dialog',
    styleUrls: ['./yt-dialog.scss'],
    templateUrl: 'yt-dialog.html',
})
export class YtDialog implements OnInit {

    constructor(
                public dialogRef: MatDialogRef<YtDialog>,
                @Inject(MAT_DIALOG_DATA) public data:  any
    ){

    }

    ngOnInit() {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(tag);

    }


    chan(ev: YT.OnStateChangeEvent) {
       // console.log(ev)
    }
}
