<div class="main-content">
    <div class="container-fluid">
        <br/><br/><br/>
        <div class="row">

            <div *ngFor="let video of playlists" class="  card col-md-4">
                <div  (click)="clicked(video.snippet)" class="card p-md-1">
                    <img  style="position: absolute; height: 40px; top: 40%; left: 45%"  src="https://www.freepnglogos.com/uploads/youtube-play-red-logo-png-transparent-background-6.png">
                    <img [src]="video.snippet?.thumbnails?.standard?.url">
                    <p class="text-center">{{ video.snippet.title }}</p>

                </div>
            </div>

        </div>
    </div>
</div>
