import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { AuthService } from '../services/auth.service';
import { Router} from "@angular/router";
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Howl, Howler} from 'howler';


declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [{




        path: '/dashboard',
        title: 'Dashboard',
        type: 'link',
        icontype: 'dashboard'
    },

    //    {
    //     path: '',
    //     title: 'Content',
    //     type: 'sub',
    //     icontype: 'apps',
    //     collapse: 'components',
    //     children: [
    //         {path: 'add-accounts', title: 'Devotions', ab:'D'},
    //         {path: 'notices', title: 'Notices', ab:'N'}
    //     ]
    // },{
    //     path: '/media',
    //     title: 'Media',
    //     type: 'link',
    //     icontype: 'content_paste',
    //
    // },

    // {
    //     path: '/media' ,
    //     title: 'Programs',
    //     type: 'link',
    //     icontype: 'fitness_center'
    //
    // },
    //
    //
    // {
    //     path: '/submit' ,
    //     title: 'Add',
    //     type: 'link',
    //     icontype: 'add'}
    //
    // }
    //
    //
    // ,
    // {
    //     path: '/articles' ,
    //     title: 'Articles',
    //     type: 'link',
    //     icontype: 'import_contacts'
    //
    // }

    // {
    //     path: '/tables',
    //     title: 'Fundraising',
    //     type: 'sub',
    //     icontype: 'grid_on',
    //     collapse: 'tables',
    //     children: [
    //         {path: 'regular', title: 'Projects', ab:'RT'},
    //         {path: 'extended', title: 'Pledges', ab:'ET'},
          
    //     ]
    // },
    // {
    //     path: '/charts',
    //     title: 'Testimonies',
    //     type: 'link',
    //     icontype: 'timeline'

    // },{
    //     path: '/calendar',
    //     title: 'Cellgroups',
    //     type: 'link',
    //     icontype: 'date_range'
    // }
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    churchname;
    username;
    comp: any = '';
    user: string;
    type: any;
     uid: string;
    usr: any = '';
    data: any= {};
    msg: any = [];
    panelOpenState: boolean = false;
    count: any = null;
    isVisible: any;
    constructor(private router: Router,
                private afs:AngularFirestore,
                private afAuth:AngularFireAuth,
                public shared:AuthService,
                private snack:MatSnackBar,
                ){

    }



    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };


    
    ngOnInit() {


        let sound = new Howl({

            src: ['https://firebasestorage.googleapis.com/v0/b/waccsa-e8589.appspot.com/o/quite-impressed-565.mp3?alt=media&token=06f27de1-b491-4406-a4d7-1036d047e7c1']
        });

        this.menuItems = ROUTES.filter(menuItem => menuItem);

        this.afAuth.authState.subscribe((res=> {
            if (res && res.uid) {
                 this.uid = res.uid
                this.afs.collection('notifications').doc(res.uid).collection('chat').valueChanges()
                    .subscribe(
                (res:any)=>{
                    this.msg = res



                    if (this.msg.length > 0){
                        sound.play();
                        this.count = this.msg.length

                        if (res[0].uid){

                            this.snack.open('New notification: '+res[0].title, 'Open', {
                                duration: 10000

                            }).onAction().subscribe(e => {
                                this.router.navigate(['/inbox/'+res[0].uid])

                            })
                        }else {
                            this.snack.open('New notification: '+res[0].title, 'Open', {
                                duration: 10000

                            }).onAction().subscribe(e => {
                                this.router.navigate(['/chat'])

                            })

                        }

                    }
                    else {
                        this.count = null
                    }

                })
                this.afs.collection('users').doc(res.uid).valueChanges().subscribe((resp: any) => {
                        this.comp = resp.comp;
                        this.data = resp
                    localStorage.setItem('user_type',this.data.type)
                    localStorage.setItem('coupon',this.data.coupon)

                    this.type = resp.type
                  //  console.log(resp)
                    this.usr = resp.first_name
                        if (!resp.comp){
                            this.comp = resp.first_name;
                        }

                })
            }
        }))
    }
    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    logOut() {
localStorage.clear()
        this.afAuth.signOut().then(()=>{
            window.open('/login','_self')

        })
    }

    openPanel() {
        this.panelOpenState = !this.panelOpenState;
    }
}
