




        <div #scrollMe [scrollTop]="scrollMe.scrollHeight"  style="background: #2da5cb"  class="sidebar-wrapper">


                <div style="margin-top: 10px; margin-left: 60px">
                    <img style="height: 115px" src="/assets/img/logo2.png"/>
                </div>



            <div class="user">
                <div class="photo">
                    <img style="width: 100%"  (error)="data.photo = './assets/img/avatar.png'" src="{{data.photo}}" />
                </div>
                <div class="user-info">
                    <a *ngIf="type === 'Plumber'" routerLink="/profile/{{uid}}"   class="collapsed">
                        <span>
                            {{usr}}

                        </span>
                    </a>

                    <a *ngIf="type === 'admin' "   class="collapsed">
                        <span>
                          Admin

                        </span>
                    </a>


                </div>
            </div>


            <ul class="nav" >


                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a [routerLink]="'/materials'"  class="nav-link">
                        <i class="material-icons">article</i>
                        <p>Resources</p>
                    </a>
                </li>




                <li routerLinkActive="active" *ngIf="type === 'admin' "  class="nav-item">
                    <!--If is a single link-->
                    <a [routerLink]="'/add-documents'"  class="nav-link">
                        <i class="material-icons">add_task</i>
                        <p>Add Resources</p>
                    </a>
                </li>



                <li routerLinkActive="active" *ngIf="type === 'Plumber' "  class="nav-item">
                    <!--If is a single link-->
                    <a href="https://qr.waccsa.co.za/register"  class="nav-link">
                        <i class="material-icons">group</i>
                        <p>Join WACC</p>
                    </a>
                </li>




<!--                <li routerLinkActive="active"  class="nav-item">-->
<!--                    &lt;!&ndash;If is a single link&ndash;&gt;-->
<!--                    <a [routerLink]="'/companies/manufacture'" *ngIf="comp === 'admin'" class="nav-link">-->
<!--                        <i class="material-icons">business</i>-->
<!--                        <p>Manufactures</p>-->
<!--                    </a>-->
<!--                </li>-->



<!--                <li routerLinkActive="active"  class="nav-item">-->
<!--                    &lt;!&ndash;If is a single link&ndash;&gt;-->
<!--                    <a [routerLink]="'/companies/insurance'" *ngIf="comp === 'admin'" class="nav-link">-->
<!--                        <i class="material-icons">business</i>-->
<!--                        <p>Insurance</p>-->
<!--                    </a>-->
<!--                </li>-->


<!--                <li routerLinkActive="active"  class="nav-item">-->
<!--                    &lt;!&ndash;If is a single link&ndash;&gt;-->
<!--                    <a [routerLink]="'/downloads'" *ngIf="comp === 'admin'" class="nav-link">-->
<!--                        <i class="material-icons">download</i>-->

<!--                        <p>Downloads</p>-->
<!--                    </a>-->
<!--                </li>-->



<!--                <li routerLinkActive="active"  class="nav-item">-->
<!--                    &lt;!&ndash;If is a single link&ndash;&gt;-->
<!--                    <a [routerLink]="'/invoices'" *ngIf="comp === 'admin'" class="nav-link">-->
<!--                        <i class="material-icons">receipt</i>-->
<!--                        <p>Invoices</p>-->
<!--                    </a>-->
<!--                </li>-->


<!--                <li routerLinkActive="active"  class="nav-item">-->
<!--                    &lt;!&ndash;If is a single link&ndash;&gt;-->
<!--                    <a [routerLink]="'/add-accounts'" *ngIf="comp === 'admin' || type === 'Company'" class="nav-link">-->
<!--                        <i class="material-icons">person_add</i>-->
<!--                        <p>Add accounts</p>-->
<!--                    </a>-->
<!--                </li>-->

<!--                <li routerLinkActive="active"  class="nav-item">-->
<!--                    &lt;!&ndash;If is a single link&ndash;&gt;-->
<!--                    <a [routerLink]="'/users'"  *ngIf="type === 'Company'"  class="nav-link">-->
<!--                        <i class="material-icons">group</i>-->
<!--                        <p>Staff Members  <mat-icon [matBadge]="count" class="material-icons" color="primary"></mat-icon></p>-->
<!--                    </a>-->
<!--                </li>-->



                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a [routerLink]="'/all-users/paid'"  *ngIf="comp === 'admin'"  class="nav-link">
                        <i class="material-icons">group</i>
                        <p>Paid Plumbers</p>
                    </a>
                </li>

                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a [routerLink]="'/all-users/unpaid'"  *ngIf="comp === 'admin'"  class="nav-link">
                        <i class="material-icons">group</i>
                        <p>Unpaid Plumbers</p>
                    </a>
                </li>



                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a [routerLink]="'/inactive-users'" *ngIf="comp === 'admin'"  class="nav-link">
                        <i class="material-icons">group</i>
                        <p>Inactive Plumbers</p>
                    </a>
                </li>
                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a [routerLink]="'/invoices'" *ngIf="type === 'admin'" class="nav-link">
                        <i class="material-icons">receipt</i>
                        <p>Invoices</p>
                    </a>
                </li>


<!--                <li routerLinkActive="active" *ngIf="data.parent"  class="nav-item">-->
<!--                    &lt;!&ndash;If is a single link&ndash;&gt;-->
<!--                    <a  class="nav-link" routerLink="/chat">-->
<!--                        <i class="material-icons">mail</i>-->
<!--                        <p>Inbox  <mat-icon [matBadge]="count" class="material-icons" color="primary"></mat-icon></p>-->
<!--                    </a>-->
<!--                </li>-->






                
<!--                <li routerLinkActive="active" routerLink="/analytics-home"  *ngIf="comp === 'admin' " class="nav-item">-->
<!--                    &lt;!&ndash;If is a single link&ndash;&gt;-->
<!--                    <a  class="nav-link" >-->
<!--                        <i class="material-icons">bar_chart</i>-->
<!--                        <p>Analytics</p>-->
<!--                    </a>-->
<!--                </li>-->





                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a class="nav-link" (click)="logOut()">
                        <i class="material-icons">power_settings_new</i>
                        <p>
                            Logout
                        </p>
                    </a>
                </li>


            </ul>

<!--            <div class="example-button-container" style="position: absolute; right: 10px; bottom: 50%">-->
<!--                <button color="warn" mat-mini-fab disabled aria-label="Example icon button with a home icon">-->
<!--                    <mat-icon color="accent">arrow_circle_down</mat-icon>-->
<!--                </button>-->
<!--            </div>-->
        </div>
