import {Routes} from '@angular/router';

import {AdminLayoutComponent} from './layouts/admin/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth/auth-layout.component';
import {RoleGuardService} from './services/role-guard.service';
import {AddUsersRoleService} from './add-users-role.service';
import {AngularFireAuthGuard, redirectUnauthorizedTo} from '@angular/fire/auth-guard';
import {AdminRoleService} from './admin-role.service';
//import {AuthGuardService} from './auth-guard.service';
//import {AngularFireAuthGuard, redirectUnauthorizedTo} from '@angular/fire/auth-guard';

//const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
export const AppRoutes: Routes = [
    {
      path: '',
      redirectTo: 'materials',
      pathMatch: 'full',



    },
    {
      path: '',
      component: AdminLayoutComponent,
      children: [


          {
                path: 'components',
                loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule)
            },


          {
                path: 'forms',
                loadChildren: () => import('./forms/forms.module').then(m => m.Forms)
            }, {
                path: 'tables',
                loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule)
            }, {
                path: 'widgets',
                loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule)
            },




            {
                path: '',
                loadChildren: () => import('./userpage/user.module').then(m => m.UserModule)
            },




          {
              path: '',
              loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
          },

          {
              path: '',
              loadChildren: () => import('./all-users/all-users.module').then(m => m.AllUsersModule),
              canActivate: [RoleGuardService]
          },



          {
              path: '',
              loadChildren: () => import('./inactive-users/inactive-users.module').then(m => m.InactiveUsersModule),
              canActivate: [RoleGuardService]
          },








          {
              path: '',
              loadChildren: () => import('./timeline/timeline.module').then(m => m.TimelineModule),


          },


          {
              path: '',
              loadChildren: () => import('./articles/articles.module').then(m => m.ArticlesModule),


          },

          {
              path: '',
              loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule),


          },


          {
              path: '',
              loadChildren: () => import('./res/resources.module').then(m => m.ResourcesModule),


          },


          {
              path: '',

              loadChildren:() => import('./invoices/invoices.module').then(m => m.InvoicesModule),
              canActivate: [RoleGuardService]
          },

          {
              path: '',
              loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule),


          },
          {
              path: '',
              loadChildren: () => import('./request/request.module').then(m => m.RequestModule),


          },


          {
              path: '',
              loadChildren: () => import('./submit/submit.module').then(m => m.SubmitModule),
              //canActivate: [AdminRoleService]

          },


          {
              path: '',
              loadChildren: () => import('./videos/videos.module').then(m => m.VideosModule),
              //canActivate: [AdminRoleService]

          },


      ],
        canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
        //  canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin() }


    },
    {
        path: '',
        loadChildren: () => import('./view-invoice/view-invoice.module').then(m => m.ViewInvoiceModule),

    },






















    {
      path: '',
      component: AuthLayoutComponent,
      children: [{
        path: '',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
      }]
    }
];
