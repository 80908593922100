export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyDUuCvlJIbZZyQIOIJIzyF85MuNhdAQM30",
    authDomain: "ipa-system.firebaseapp.com",
    databaseURL: "https://ipa-system-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "ipa-system",
    storageBucket: "ipa-system.appspot.com",
    messagingSenderId: "974501596974",
    appId: "1:974501596974:web:d763902175754e3a80b4bf"
  }
};


