import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {AngularFireAuth} from '@angular/fire/auth';
import {NotificationService} from './services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {AuthService} from './services/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase';
import FieldValue = firebase.firestore.FieldValue;


@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html',
     styleUrls:  ['./app.scss']
})

export class AppComponent implements OnInit {
  private _router: Subscription;
     user: any;

  constructor( private router: Router,
               private n:NotificationService,
               private spinner: NgxSpinnerService,
               private auth:AuthService,
               private afs:AngularFirestore,
               //private d:Shared,
               private afAuth:AngularFireAuth) {
    // afAuth.authState.subscribe(res=>{
    //   if (res && res.uid){
    //     console.log(res.uid+"   logged in")
    //  //   this.router.navigate(['/dashboard'])
    //   } else {
    //  //   this.router.navigate(['/login'])
    //   }
    // })

      console.log(this.detectBrowser())
  }




    detectBrowser() {
        if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
            return 'Opera';
        } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
            return 'Chrome';
        } else if(navigator.userAgent.indexOf("Safari") != -1) {
            return 'Safari';
        } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
            return 'Firefox';
        }
         else {
            return 'Unknown';
        }
    }

    ngOnInit() {

      this.spinner.show()




      this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
        const body = document.getElementsByTagName('body')[0];
       this.spinner.hide();
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (body.classList.contains('modal-open')) {
          body.classList.remove('modal-open');
          modalBackdrop.remove();
        }
      });
    }



}
